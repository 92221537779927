<template>
    <div>
        <div class="table_content">

            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="缴费状态:">
                        <el-select v-model="form.region" @change="payState()" placeholder="请选择审批状态" size="mini"
                                   clearable>
                            <el-option label="已缴费" value="3"></el-option>
                            <el-option label="未缴费" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="户室号:">
                        <el-input v-model="form.name" placeholder="请输入户室编号" size="mini"></el-input>
                    </el-form-item>
					<el-form-item label="身份证号:">
					    <el-input v-model="form.idCard" placeholder="请输入身份证号" size="mini"></el-input>
					</el-form-item>
					<el-form-item label="小区名称:">
					    <el-input v-model="form.villageName" placeholder="请输入小区名称" size="mini"></el-input>
					</el-form-item>
                    <button style="cursor: pointer;" @click.prevent="common()">搜索</button>
                    <!--                    <button style="cursor: pointer;" @click.prevent="createOrder" v-show="userType">新增订单</button>-->
                </el-form>
            </div>

            <el-card>

                <el-table :data="tableData"
                          style="width:100%"
                        
                          :default-sort="{prop: 'settingTime', order: 'descending'}"
                          :stripe="true"
                          v-loading="loading"
                          element-loading-text="拼命加载中"
                >
                    <el-table-column prop="villageName" label="小区名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="towerNum" label="楼幢号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unitName" label="单元名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="houseNum" label="户室号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="ownerName" label="业主姓名" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="idCard" width="95px" label="身份证号码" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" show-overflow-tooltip>
                    </el-table-column>
                    <!--                <el-table-column prop="assessment" label="缴费金额" show-overflow-tooltip>-->
                    <!--                </el-table-column>-->
                    <el-table-column prop="assessment" label="应缴金额" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="standardAmount" label="标准金额" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="houseacctBal" label="账户余额" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="payTime" label="缴费时间" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="orgStat" label="缴费状态" width="120" :formatter="formatStat">
                    </el-table-column>

                    <!--                <el-table-column fixed="right" label="操作" width="100">-->
                    <!--                    <template slot-scope="scope">-->
                    <!--                        <el-button @click.native.prevent="detail(scope.row)" type="text"-->
                    <!--                                   size="mini">-->
                    <!--                            查看详情-->
                    <!--                        </el-button>-->
                    <!--                    </template>-->
                    <!--                </el-table-column>-->
                </el-table>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "order",
        components: {
            commonTitle
        },
        data() {
            return {
                url: this.$Config.base_server,
                tableData: [],//表格数据
                totalNum: 0,
                loading: false,
                form: {
                    name: "",
                    region: '',
                },
                userType: '', //记录用户状态
                data: {
                    limit: 15,
                    access_token: localStorage.getItem("token")
                }
            }
        }, mounted() {
            //获取用户状态 业主登录时 隐藏新增订单按钮
            let type = JSON.parse(localStorage.getItem("user")).type;
            if (type == 3) {
                //业主
                this.userType = false;
            } else {
                this.userType = true;
            }
            this.common();
        }, methods: {
            common(currentPage = 0) {
                //初始化数据
                const _this = this;
                _this.loading = true;

                _this.data.page = currentPage;
                _this.data.houseNum = _this.form.name;
                _this.data.orgStat = _this.form.region;
				_this.data.idCard = _this.form.idCard;
				_this.data.villageName = _this.form.villageName;
                //查询当前登录者的订单信息
                $.ajax({
                    url: _this.url + "/api-public/busiorder/list",
                    type: 'GET',
                    data: _this.data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.tableData = res.data;
                        _this.totalNum = res.count;
                    },
                    error: function (res) {
                        _this.loading = false;
                    }
                });
            }, handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                // this.currpage = val;
                this.common(val);
            }, formatTime(row) {
                //格式化时间
                let date = new Date(row.creatTime);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return date.getFullYear() + "-" + month + "-" + day + "-" + hour + ":" + minutes + ":" + second;
            }, detail(row) {
                //查看详情按钮
                this.$router.push('/orderDetail');
                //存储订单编号
                localStorage.setItem("orderNo", row.orderNo);
            }, createOrder() {
                //生成订单按钮
                this.$router.push({"name": "checkHouse"});
            }, formatStat(row) {
                //格式化订单状态
                if (row.orgStat == 1) {
                    return '生成';
                } else if (row.orgStat == 2) {
                    return '未缴费';
                } else if (row.orgStat == 3) {
                    return '已缴费';
                } else if (row.orgStat == 4) {
                    return '作废';
                } else if (row.orgStat == 5) {
                    return '已分摊';
                }
            }, farmatterOrderType(row) {
                if (row.orderType == 1) {
                    return '开发商';
                }
                if (row.orderType == 0) {
                    return '物业';
                }
                if (row.orderType == 2) {
                    return '业主委员会';
                }
                if (row.orderType == 3) {
                    return '业主';
                }
            }, payState(currentPage = 0) {
                //缴费状态
                //初始化数据
                const _this = this;
                _this.loading = true;
                const data = {
                    access_token: localStorage.getItem("token"),
                    orgStat: _this.form.region,
                    houseNum: _this.form.name,
					idCard:_this.form.idCard,
                    page: currentPage,
                    limit: 15,
                }
                //查询当前登录者的订单信息
                $.ajax({
                    url: _this.url + "/api-public/busiorder/list",
                    type: 'GET',
                    data: data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.tableData = res.data;
                        _this.totalNum = res.count;
                    },
                    error: function (res) {
                        _this.loading = false;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>